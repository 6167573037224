import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Leg from "../components/Applications/LEG";
import { graphql } from "gatsby";
import { Seo } from "../components/App/SEO";

const LEGPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("LEG")}
        homePageText="Home"
        homePageUrl="/"
        activePageText={t("Anwendungen")}
      />
      <Leg />
      {/*<RelatedServices />*/}
      <Footer />
    </Layout>
  );
};

export default LEGPage;

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <Seo
    title="Lokale Elektrizitätsgemeinschaft (LEG) "
    description="Eigentümern von Solaranlagen profitieren am besten von Ihrer PV-Anlage, wenn sie einen ZEV bilden. Warum, wie und wann erfahren Sie bei Blockstrom"
  />
);
